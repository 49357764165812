import { FC } from 'react'
import {
  LinkBlockProps,
  QuoteBlockProps,
  SectionTechstackSliderProps,
  TechstackSlide,
} from '@portfolio/models'
import { styled } from '@portfolio/styles'
import {
  Link,
  Media,
  Pill,
  SectionWrapper,
  Slider,
} from '@portfolio/components'

const StyledSectionWrapper = styled(SectionWrapper, {
  margin: '$mobileSectionMarginL 0',
  backgroundColor: '$darkThemeBackground',
  color: '$white',

  '@md': {
    margin: '$desktopSectionMarginL 0',
  },
})

const Content = styled('div', {
  display: 'grid',
  gap: '$36',
  margin: '0 auto',
  containerSpace: 'medium',
  width: '100%',
})

const Title = styled('h2', {
  projectFont: 'heading02',
  textAlign: 'center',
  padding: '0 $20',
  marginBottom: '$96',

  '@lg': {
    marginBottom: '$120',
  },
})

const CardWrapper = styled('div', {
  padding: '$24',
  backgroundColor: '$techstackCardsBackground',
  borderRadius: '$r3',
  maxWidth: '500px',
  width: '100%',
  margin: '0 auto',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gap: '$48',
  '@lg': {
    padding: '$32',
    gap: '$64',
  },
})

const UpperContent = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$24',

  '@lg': {
    gap: '$32',
  },
})

const Description = styled('div', {
  projectFont: 'heading08',
  color: '$white_07',
  maxWidth: '360px',
})

const LogoWrap = styled('div', {
  variants: {
    size: {
      small: {
        width: '110px',
        minHeight: '30px',
      },
      big: {
        width: '120px',
        height: 'auto',
      },
    },
  },
})

const ImageWrapper = styled('div', {
  borderRadius: '$r2',
  overflow: 'hidden',
  width: '80px',
  height: '80px',

  '@md': {
    width: '100px',
    height: '100px',
  },
})

const QuoteBlockWrapper = styled('div', {
  projectFont: 'body03',
  color: '$white_05',
  display: 'grid',
  gap: '$20',
  gridAutoFlow: 'row',
  '@md': {
    gap: '$32',
    gridTemplateColumns: '100px 1fr',
    gridTemplateRows: '1fr',
  },
})

const Quote = styled('p', {
  color: '$white',
})

const LinkBlockWrapper = styled('div', {
  backgroundColor: '$techstackLinkBlockBackground',
  borderRadius: '$r3_mobile',
  padding: '$16',
  display: 'grid',
  gap: '$8',
  alignItems: 'center',
  position: 'relative',

  '&::before': {
    content: '',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'linear-gradient(to left top, #1E689B, #009385)',
    borderRadius: '$r3_mobile',
    border: '1px solid transparent',
    maskComposite: 'exclude',
    '-webkit-mask':
      'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    '-webkit-mask-composite': 'destination-out;',
  },

  '@md': {
    gridAutoFlow: 'column',
    gap: '$24',
  },
})

const LinkBlockContent = styled('div', {
  projectFont: 'ui04',
  color: '$white_07',
  width: '100%',
  zIndex: 10,
})

const LinkWrapper = styled('span', {
  color: '$white',
  display: 'inline',
})

const MobileContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$20',
  padding: '0 $20',
  margin: '$0 auto',
})

const Pills = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  listStyleType: 'none',
  gap: '$8',
})

const BlockWrap = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$24',
  '& > *:last-child': {
    alignSelf: 'end',
  },

  '@lg': {
    gap: '$32',
  },
})

const DesktopVisibilityContainer = styled('div', {
  display: 'none',

  '@lg': {
    display: 'block',
  },
})
const MobileVisibilityContainer = styled('div', {
  display: 'block',

  '@lg': {
    display: 'none',
  },
})

const QuoteBlock: FC<QuoteBlockProps> = ({ name, quote, role, image }) => {
  return (
    <QuoteBlockWrapper>
      {image && (
        <ImageWrapper>
          <Media {...image} />
        </ImageWrapper>
      )}

      <div>
        {quote && <Quote>{quote}</Quote>}

        {name && <p>{name}</p>}

        {role && <p>{role}</p>}
      </div>
    </QuoteBlockWrapper>
  )
}

const LinkBlock: FC<LinkBlockProps> = ({ logo, content, cta, ctaLabel }) => {
  return (
    <LinkBlockWrapper>
      {logo?.mediaPayload.type === 'sanity-image' &&
        logo?.mediaPayload.image.asset && (
          <LogoWrap size="small">
            <Media {...logo} />
          </LogoWrap>
        )}

      <LinkBlockContent>
        {content && (
          <>
            <p>{content} </p>
            <p>
              <LinkWrapper>
                <Link {...cta}>{ctaLabel}</Link>
              </LinkWrapper>
            </p>
          </>
        )}
      </LinkBlockContent>
    </LinkBlockWrapper>
  )
}

const Card: FC<TechstackSlide> = ({
  logo,
  description,
  elements,
  footerBlocks,
}) => {
  return (
    <CardWrapper>
      <UpperContent>
        {logo && (
          <LogoWrap size="small">
            <Media {...logo} />
          </LogoWrap>
        )}
        {description && <Description>{description}</Description>}

        {elements?.length && (
          <Pills>
            {elements?.map((element) => (
              <Pill key={element} appearance="UI06Uppercase">
                {element}
              </Pill>
            ))}
          </Pills>
        )}
      </UpperContent>

      {footerBlocks?.length && (
        <BlockWrap>
          {footerBlocks?.map((block) => {
            if (block._type === 'linkBlock') {
              return <LinkBlock {...block} />
            }

            if (block._type === 'quoteBlock') {
              return <QuoteBlock {...block} />
            }

            return null
          })}
        </BlockWrap>
      )}
    </CardWrapper>
  )
}

export const SectionTechstackSlider: FC<SectionTechstackSliderProps> = ({
  title,
  slides,
}) => {
  if (!slides?.length) return null

  return (
    <StyledSectionWrapper type="DEFAULT">
      <Content>{title && <Title>{title}</Title>}</Content>

      <DesktopVisibilityContainer>
        <Slider>
          {slides?.map((slide) => (
            <Card key={slide?._key} {...slide} />
          ))}
        </Slider>
      </DesktopVisibilityContainer>

      <MobileVisibilityContainer>
        <MobileContainer>
          {slides?.map((slide) => (
            <Card key={slide?._key} {...slide} />
          ))}
        </MobileContainer>
      </MobileVisibilityContainer>
    </StyledSectionWrapper>
  )
}
